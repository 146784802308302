.fixed-background-section {
    position: relative;
    height: 120vh; /* Adjust the height as needed */
    background: url('../assets/images/main.jpg') no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

.overlay {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
}

.main-caption {
    font-size: 4em;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* 텍스트 그림자 추가 */
}

.sub-caption {
    font-size: 1.2em;
    margin-top: 1em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 텍스트 그림자 추가 */
}

@media (max-width: 768px) {
    .main-caption {
        font-size: 1.5em;
    }
    .sub-caption {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .main-caption {
        font-size: 1.2em;
    }
    .sub-caption {
        font-size: 0.9em;
    }
}
