.login-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
}

.login-form div {
    margin-bottom: 10px;
}

.login-form label {
    margin-bottom: 5px;
}

.login-form input {
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    padding: 10px 15px;
    font-size: 1em;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}
