.home {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.bar-image {
    width: auto;
    height: 1.5cm;
}

.title {
    /*font-size: 1.7em;*/
    font-size: 2.5vh;
    text-align: center;
    margin-bottom: 20px;
}

.section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}



.section {
    flex: 1 1 25%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.responsive-image {
    max-width: 50px; /* 이미지 크기를 줄임 */
    height: auto;
    cursor: pointer;
}

@media (max-width: 768px) {
    .section {
        flex: 1 1 50%;
        padding: 5px;
    }

    .section-container {
        flex-direction: column;
    }
}

h3 {
    font-size: 1.2em;
    margin-top: 10px;
}

.footer-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #232323;
    color: #fff;
    padding: 20px 0;
}

footer p {
    margin: 0;
}

footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 1.5em;
}

footer a:hover {
    color: #bbb;
}

footer .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

@media (max-width: 768px) {
    footer .footer-content {
        flex-direction: column;
        text-align: center;
    }

    footer .footer-content div {
        margin-bottom: 10px;
    }

    footer a {
        font-size: 1.2em;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.section {
    flex: 1 1 25%;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0; /* 초기 상태를 숨김 */
    animation: fadeIn 0.9s forwards; /* 애니메이션 추가 */
    animation-delay: var(--delay, 100s); /* 지연 시간 추가 */
}

@media (max-width: 768px) {
    .section {
        flex: 1 1 100%;
        padding: 90px;
    }

    .section-container {
        flex-direction: column;
    }

    .section.show {
        opacity: 1;
        animation: fadeIn 0.5s forwards;
    }
}

.responsive-image {
    max-width: 10px; /* 이미지 크기를 줄임 */
    height: auto;
    cursor: pointer;
}

.title {
    font-size: 2.5vh;
    text-align: center;
    margin-bottom: 20px;
    word-break: break-word;
}

h3 {
    font-size: 1.2em;
    margin-top: 10px;
}
